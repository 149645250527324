import { async } from "@firebase/util";
import { useEffect } from "react";
import { useState } from "react";

const CreateSessionForStudent = () => {
  const [exams, setExams] = useState([]);
  const [students, setStudents] = useState([]);
  const [error,setError] = useState('')

  const submitForm = async (event) => {
    event.preventDefault()
    console.log(exams[event.target.exam.options.selectedIndex])
    console.log(parseInt(event.target.student.value))

    const pkg = {
      test:exams[event.target.exam.options.selectedIndex],
      testTakerId:parseInt(event.target.student.value)
    }

    const response = await fetch("https://webapplication120221210090459.azurewebsites.net/api/getexamsession", {
      method: "POST",
      body: JSON.stringify(pkg),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const da = await response.json();

    console.log(da)
    setError(da)
  }

  const getSATTests = async () => {
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/getsattests",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();
    setExams(da);
  };

  const getStudents = async () => {
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/getstudents",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();

    setStudents(da);
  };

  useEffect(() => {
    getSATTests();
    getStudents();
  }, []);

  return (
    <div class="container">
        {error === '' ? <br></br> : <p>{Error}</p>}
      <form onSubmit={submitForm}>
        <label>Öğrenciler : </label>
        <select class="form-control text-center mb-3" name="student">
        {students.map((stu) => {
                return <option value ={stu.id}>{stu.fullName}</option>
            })}
        </select>
        <label>Sınavlar : </label>
        <select name="exam" class="form-control text-center mb-3">
            {exams.map((exam) => {
                return <option value ={exam.id}>{exam.testName}</option>
            })}
          
        </select>
        <input class="btn btn-primary" type="submit" value="Tanımla"></input>
      </form>
      <br></br>
      <p>{error}</p>
    </div>
  );
};

export default CreateSessionForStudent