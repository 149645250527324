import { useSelector } from "react-redux";



const Welcome = (props) =>{

    const user = useSelector(state=>state.auth.userFullName);
    console.log(user);
    console.log(props.User);
    return (
        <div>
            <h1>Welcome {user}</h1>
        </div>
    )
}

export default Welcome