import { Fragment, useEffect, useState } from "react";
import Timer from "../../Timer/Timer";
import "./ReadingTest.css";

const ReadingTest = (props) => {
  const [index, setIndex] = useState(0);
  const [qno, setqNo] = useState(1)
  const [questions,setQuestions] = useState(props.questions.questionDtos);
  

  const [questionExample, setQuestionExample] = useState(
    props.questions.questionDtos[index]
  );
  const [finishExam, setFinishExam] = useState(false);
  const [selected, setSelected] = useState("");


  const NextQuestion = () => {
    if (index === props.questions.questionDtos.length - 1) {
      setFinishExam(true);
      return;
    }
    setQuestionExample(props.questions.questionDtos[index + 1]);
    setIndex(index + 1);
    setSelected('')

  };

  const PreviousQuestion = () => {
    if (index === 0) {
      return;
    }
    if (finishExam === true) {
      setFinishExam(false);
      return;
    }
    setSelected('')
    setQuestionExample(props.questions.questionDtos[index - 1]);
    setIndex(index - 1);
  };

  const SetSelectedForQuestion = (select) => {
    setSelected(select)
    questions[index].selected = select; 
  }

  

  /**
   * {`option ${
    props.item.Answer4 === props.item.Selected ? "selected" : ""
  } `}
   */

  return (
    <div className="container">
      <Timer duration={props.duration} TimeUp={() => {props.finishTest(questions)}}></Timer>

     
      {props.questions.questionDtos.map((question) => {
        let cssclass = 'btn btn-outline-dark'
        if(index == props.questions.questionDtos.indexOf(question)){
            cssclass = 'btn btn-secondary'
        }
        else if(questions[props.questions.questionDtos.indexOf(question)].selected !== ''){
            cssclass = 'btn btn-outline-primary'
        }
        let value = <button onClick={(event) => {
          event.preventDefault(); 
          setQuestionExample(props.questions.questionDtos[props.questions.questionDtos.indexOf(question)]);
          setIndex(props.questions.questionDtos.indexOf(question));
          setSelected('')
          if (finishExam === true) {
            setFinishExam(false);
          }
        }} className={`qnavbuttonnew ${cssclass}
        `}>{props.questions.questionDtos.indexOf(question) + 1}</button>

      
          
        if(props.questions.questionDtos.indexOf(question) % 20 === 0){
          return ( <Fragment>
            <br></br> {value}
            </Fragment>)
        }
        else{
          return value;
        }
       
      })}




      {finishExam === false && (
        <Fragment>
          <div className="questionContainer clearfix">
            <div className="questionText">
              <img
                className="questionTextImage"
                src={questionExample.references["QuestionText"]}
              ></img>
              <p>{questionExample.question.questionText}</p>
            </div>
            <div className="questionAnswers clearfixchild">
              {questions[index].question.questionType === 'Test' ?               <div>
                <div
                  onClick={() => SetSelectedForQuestion("A")}
                  className={`potentialAnswerDiv ${
                    questions[index].selected === "A" && "selected "
                  }`}
                >
                  {typeof questionExample.references["Answer1"] ===
                  "undefined" ? (
                    ""
                  ) : (
                    <img
                      className="potentialAnswerImage"
                      src={questionExample.references["Answer1"]}
                    ></img>
                  )}
                  <p>{questionExample.question.potentialAnswers[0].answer}</p>
                </div>

                <div
                  onClick={() => SetSelectedForQuestion("B")}
                  className={`potentialAnswerDiv ${
                    questions[index].selected === "B" && "selected "
                  }`}
                >
                  {typeof questionExample.references["Answer2"] ===
                  "undefined" ? (
                    ""
                  ) : (
                    <img
                      className="potentialAnswerImage"
                      src={questionExample.references["Answer2"]}
                    ></img>
                  )}
                  <p>{questionExample.question.potentialAnswers[1].answer}</p>
                </div>

                <div
                  onClick={() => SetSelectedForQuestion("C")}
                  className={`potentialAnswerDiv ${
                    questions[index].selected === "C" && "selected "
                  }`}
                >
                  {typeof questionExample.references["Answer3"] ===
                  "undefined" ? (
                    ""
                  ) : (
                    <img
                      className="potentialAnswerImage"
                      src={questionExample.references["Answer3"]}
                    ></img>
                  )}
                  <p>{questionExample.question.potentialAnswers[2].answer}</p>
                </div>

                <div
                  onClick={() => SetSelectedForQuestion("D")}
                  className={`potentialAnswerDiv ${
                    questions[index].selected === "D" && "selected "
                  }`}
                >
                  {typeof questionExample.references["Answer4"] ===
                  "undefined" ? (
                    ""
                  ) : (
                    <img
                      className="potentialAnswerImage"
                      src={questionExample.references["Answer4"]}
                    ></img>
                  )}
                  <p>{questionExample.question.potentialAnswers[3].answer}</p>
                </div>
              </div> : ''}

              {questions[index].question.questionType === 'Open-Ended' ? <Fragment>
                <input type="text" onChange={(event) => {SetSelectedForQuestion(event.target.value)} } value={questions[index].selected}></input>
              </Fragment>: ''}

            </div>
          </div>

          <button onClick={PreviousQuestion} className="qnavbutton">Previous Question</button>

          <button onClick={NextQuestion} className="qnavbutton">Next Question</button>
        </Fragment>
      )}

      {finishExam === true ? (
        <div>
          {" "}
          <button onClick={PreviousQuestion} className="qnavbutton">Previous Question</button>
          <button onClick={() => {props.finishTest(questions)}} className="qnavbutton">Finish Test</button>{" "}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default ReadingTest;
