import { Fragment, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import SATSelection from "../../SATSelectionModal/SATSelection";
import Modal from "../../UI/Modal";
import MathematicsTest from "../MathematicsTest/MathematicsTest";
import ReadingTest from "../ReadingTest/ReadingTest";
import { useSelector } from "react-redux";
import "./SATOverview.css";

const SATOverview = () => {
  const User = useSelector((state) => state.auth);
  const history = useHistory();

  const [sessionId, setSessionId] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [questions, setQuestions] = useState();
  const [test, setTest] = useState();
  const [testSelected, setTestSelected] = useState(false);
  const [isBusy, setIsBusy] = useState(false);

  console.log(test);

  //State korumak için Redux kullanılabilir.
  //Refreshde soru korumak için de

  const [readingTestCompletion, setReadingTestCompletion] = useState(false);
  const [readingTestCompletion2, setReadingTestCompletion2] = useState(false);

  const [mathematicsTestCompletion, setMathematicsTestCompletion] =
    useState(false);
  const [mathematicsTest2Completion, setMathematicsTest2Completion] =
    useState(false);

  const [readingTestVisibility, setReadingTestVisibility] = useState(true);
  const [readingTestVisibility2, setReadingTestVisibility2] = useState(false);

  const [mathTestVisibility, setMathTestVisibility] = useState(false);
  const [mathTestVisibility2, setMathTestVisibility2] = useState(false);

  const [activeTest, setActiveTest] = useState("");

  console.log(isBusy);

  const getQuiz = async (type) => {
    const req = {
      examId: test.id,
      testType: type,
    };
    const response = await fetch("https://webapplication120221210090459.azurewebsites.net/api/getquiz", {
      method: "POST",
      body: JSON.stringify(req),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const da = await response.json();
    console.log(da);
    for (let i = 0; i < da.questionDtos.length; i++) {
      da.questionDtos[i].selected = "";
    }
    setQuestions(da);
    console.log(da);
  };

  const submitReadingTest = (testAnswers) => {
    console.log(testAnswers);
    console.log("Reading 1 Test Finished");
    console.log("Finish1");

    const testid = testAnswers.quizID;

    setReadingTestCompletion(true);
    setReadingTestVisibility(false);
    setReadingTestVisibility2(true);

    console.log("Finish1");
    submitTest(testAnswers);
    console.log("Finish2");
    setQuestions();
    console.log("Finish3");

    setActiveTest("");
  };

  const submitReadingTest2 = (testAnswers) => {
    console.log("Reading 2 Test Finished");

    const testid = testAnswers.quizID;

    submitTest(testAnswers);
    console.log("read2");
    setQuestions();
    setReadingTestCompletion2(true);
    setReadingTestVisibility2(false);
    setMathTestVisibility(true);
    setActiveTest("");
  };

  const submitMathematicsTest = (testAnswers) => {
    console.log("Math 1 Test Finished");
    const testid = testAnswers.quizID;

    submitTest(testAnswers);
    setMathematicsTestCompletion(true);
    setMathTestVisibility(false);
    setMathTestVisibility2(true);
    setActiveTest("");
  };

  const submitMathematicsTest2 = (testAnswers) => {
    console.log("Math 2 Test Finished 2");
    const testid = testAnswers.quizID;

    submitTest(testAnswers);
    setMathematicsTest2Completion(true);
    setMathTestVisibility2(false);
    setActiveTest("");

    console.log("A C");
  };

  const GetReadingTest1 = async () => {
    setActiveTest("Reading");
    setIsLoading(true);

    await getQuiz("Reading Module 1");

    setIsLoading(false);

    //Request to Get Reading Test
    //store data in const
    //history.push with question data and
    //submitReadingTest();
  };

  const GetReadingTest2 = async () => {
    setActiveTest("Reading2");
    setIsLoading(true);

    await getQuiz("Reading Module 2");

    setIsLoading(false);

    //Request to Get Reading Test
    //store data in const
    //history.push with question data and
    //submitReadingTest();
  };

  const GetWritingAndLanguageTest = () => {
    console.log("Submitted");
    setActiveTest("Writing");
    console.log(activeTest);
    //submitWritingTest();
  };

  const GetMathematicsTest = async () => {
    setActiveTest("Mathematics");
    console.log("Math Test");
    setIsLoading(true);

    await getQuiz("Mathematics Module 1");

    setIsLoading(false);

    console.log("Redirected");
    console.log(1);
    //submitMathematicsTest();
  };

  const GetMathematicsTest2 = async () => {
    setActiveTest("Mathematics2");
    setIsLoading(true);

    await getQuiz("Mathematics Module 2");

    setIsLoading(false);

    console.log("Math Test 2");
    console.log("Redirected");
    //submitMathematicsTest();
  };

  const SubmitData = () => {
    console.log("Exam Finished");
    history.push({
      pathname: "./SATResult",
      state: sessionId,
    });
  };

  const startExam = async (exam) => {
    console.log(exam.status);
    setSessionId(exam.id);
    setTestSelected(true);
    setTest(exam.test);

    if (exam.status === "Not Started") {
      console.log("Start Reading 1");
    } else if (exam.status === "Reading1") {
      setReadingTestCompletion(true);
      setReadingTestVisibility(false);
      setReadingTestVisibility2(true);

      console.log("Start Reading 2");
    } else if (exam.status === "Reading2") {
      setReadingTestCompletion(true);
      setReadingTestCompletion2(true);
      setReadingTestVisibility(false);
      setMathTestVisibility(true);
    } else if (exam.status === "Math1") {
      setReadingTestCompletion(true);
      setReadingTestCompletion2(true);
      setMathematicsTestCompletion(true);
      setReadingTestVisibility(false);
      setMathTestVisibility2(true);

      console.log("Start Math 2");
    }
  };

  const submitTest = async (questionsDto) => {
    setIsBusy(true);
    console.log("Busy");
    questionsDto.userID = User.userid;

    console.log("SUBMIT");

    questionsDto.quizID = questions.quizID;

    const pkg = {
      quizQuestions: [],
      quizID: questionsDto.quizID,
      userID: User.userid,
      sessionID: sessionId,
    };

    for (let i = 0; i < questionsDto.length; i++) {
      pkg.quizQuestions.push({
        question: questionsDto[i].question,
        Selected: questionsDto[i].selected,
      });
    }

    console.log(pkg);

    const response = await fetch("https://webapplication120221210090459.azurewebsites.net/api/send", {
      method: "POST",
      body: JSON.stringify(pkg),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const da = await response.json();
    console.log(da);

    console.log("Finished Busy");

    await 15000;
    setIsBusy(false);
  };

  if (isLoading === true) {
    return <p>Yükleniyor</p>;
  }

  if (testSelected === false) {
    return (
      <Modal>
        <SATSelection startSAT={startExam}></SATSelection>
      </Modal>
    );
  }

  if (activeTest === "Reading") {
    return (
      <Fragment>
        <ReadingTest
          finishTest={(questions) => {
            submitReadingTest(questions);
          }}
          duration={39}
          questions={questions}
        ></ReadingTest>
      </Fragment>
    );
  }

  if (activeTest === "Reading2") {
    return (
      <Fragment>
        <ReadingTest
          finishTest={(questions) => {
            submitReadingTest2(questions);
          }}
          duration={39}
          questions={questions}
        ></ReadingTest>
      </Fragment>
    );
  }

  if (activeTest === "Mathematics") {
    return (
      <Fragment>
        <ReadingTest
          finishTest={(questions) => {
            submitMathematicsTest(questions);
          }}
          duration={43}
          questions={questions}
        ></ReadingTest>
      </Fragment>
    );
  }

  if (activeTest === "Mathematics2") {
    return (
      <Fragment>
        <ReadingTest
          finishTest={(questions) => {
            submitMathematicsTest2(questions);
          }}
          duration={43}
          questions={questions}
        ></ReadingTest>
      </Fragment>
    );
  }

  if (
    readingTestCompletion === true &&
    mathematicsTestCompletion === true &&
    readingTestCompletion2 === true &&
    mathematicsTest2Completion === true
  ) {
    return (
      <div class="containerFill">
        <div className="container">
          {isBusy === true ? <p>Cevaplarınız Yükleniyor</p> : ""}
          <button
            disabled={isBusy}
            className={`testButtonLink ${isBusy === true ? "disabled" : ""}`}
            onClick={SubmitData}
          >
            Finish SAT TEST
          </button>
        </div>
      </div>
    );
  }

  return (
    <div class="containerFill">
      <div className="container">
        <p>Overview</p>
        <button
          className={`testButtonLink ${
            readingTestVisibility === true ? "" : "disabled"
          }`}
          onClick={GetReadingTest1}
          disabled={readingTestVisibility === true ? false : true}
        >
          Reading Test Module 1
        </button>
        <br></br>

        <button
          className={`testButtonLink ${
            readingTestVisibility2 === true ? "" : "disabled"
          }`}
          onClick={GetReadingTest2}
          disabled={readingTestVisibility2 === true ? false : true}
        >
          Reading Test Module 2
        </button>
        <br></br>
        <button
          className={`testButtonLink ${
            mathTestVisibility === true ? "" : "disabled"
          }`}
          onClick={GetMathematicsTest}
          disabled={mathTestVisibility === true ? false : true}
        >
          Mathematics Module 1
        </button>
        <br></br>
        <button
          className={`testButtonLink ${
            mathTestVisibility2 === true ? "" : "disabled"
          }`}
          onClick={GetMathematicsTest2}
          disabled={mathTestVisibility2 === true ? false : true}
        >
          Mathematics Module 2
        </button>
      </div>
    </div>
  );
};

export default SATOverview;
