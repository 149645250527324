import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import "../SATResult/SATResult.css";
import { Link, useHistory } from "react-router-dom";

const StudentSATResults = () => {
  const User = useSelector((state) => state.auth);

  const history = useHistory();

  console.log(User.userid);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getStudentSATResults();
  }, []);

  const getStudentSATResults = async () => {
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/getstudentsatresults",
      {
        method: "POST",
        body: JSON.stringify(parseInt(User.userid)),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();
    console.log(da);

    setResults(da);

    setIsLoading(false);
  };

  const getStudentQuizDetails = async (quizId) => {
    console.log(quizId);
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/student/getsatresultdetails",
      {
        method: "POST",
        body: JSON.stringify(quizId),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();
    console.log(da);
    console.log('Redirect')
    setIsLoading(false);

    history.push({
      pathname: "./satresultdetails",
      state: da,
    });
  };

  if (isLoading === true) {
    return <p>Loading ...</p>;
  }

  if (isLoading === false) {
    return (
      <div class="containerFill">
      <div className="container">
        <p>Student Results</p>


        <table class="table table-dark table-bordered">
          <thead class="thead-dark">
            <tr>
              <th>Exam Name</th>
              <th>Reading Module 1</th>
              <th>Reading Module 2</th>
              <th>Mathematics Module 1</th>
              <th>Mathematics Module 2</th>
              <th>R/W Score</th>
              <th>Math Score</th>
              <th>Total Score</th>
              <th></th>
            </tr>
          </thead>
          <tbody class="tbody-dark">
            {results.map((str) => {
              return (
                <tr>
                  <td>{str.examName}</td>
                  <td>{str.totalReadingRights} / 33</td>
                  <td>{str.totalReadingWrongs} / 33</td>
                  <td>{str.totalMathRights} / 27</td>
                  <td>{str.totalMathWrongs} / 27</td>
                  <td>{str.readingScore} / 800</td>
                  <td>{str.mathScore} / 800</td>

                  <td>{str.score} / 1600</td>
                  <td>
                  {User.role === 'Admin' ? ''
                    :
                    <button
                      onClick={() => {
                        getStudentQuizDetails(str.examID);
                      }}
                      class="btn btn-light"
                    >Details</button>
                    }
                 

                   
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <br></br>

        <button
          className="btn btn-primary"
          onClick={() => history.push("./Index")}
        >
          Back To Menu
        </button>
      </div>
      </div>
    );
  }
};

export default StudentSATResults;
