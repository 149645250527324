import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

const StudentSATResultDetails = () => {
  const loc = useLocation();
  const history = useHistory();

  const x = loc.state;
  console.log(x);
  const [questions, setQuestions] = useState([]);

  const getAnsweredExamQuestions = async (id) => {
    console.log(id);
    //history.goBack()

    const response = await fetch(
      "https://localhost:5001/api/getsatquizdetails",
      {
        method: "POST",
        body: JSON.stringify(id),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();

    history.push({
      pathname: "./satresultquizdetails",
      state: da,
    });
  };

  return (
    <div class="containerFill">
      <div className="container">
        <table className="table table-dark table-bordered">
          <thead className="thead-dark">
            <tr>
              <th scope="col">Module</th>

              <th>True Answers</th>
              <th>Wrong Answers</th>
              <th>Blank Answers</th>
              
              <th></th>
            </tr>
          </thead>
          <tbody class="tbody-dark">
            <tr>
              <td>Reading And Writing Module 1</td>
              <td>{x.readingWritingTestModule1.totalRightAnswers}/33</td>
              <td>{x.readingWritingTestModule1.totalWrongAnswers}/33</td>
              <td>{x.readingWritingTestModule1.totalBlankAnswers}/33</td>
              <td>
                <button
                  onClick={() =>
                    getAnsweredExamQuestions(x.readingWritingTestModule1.id)
                  }
                  class="btn btn-light"
                >
                  Check Exam
                </button>
              </td>
            </tr>
            <tr>
              <td>Reading And Writing Module 2</td>
              <td>{x.readingWritingTestModule2.totalRightAnswers}/33</td>
              <td>{x.readingWritingTestModule2.totalWrongAnswers}/33</td>
              <td>{x.readingWritingTestModule2.totalBlankAnswers}/33</td>
              <td>
                <button
                  onClick={() =>
                    getAnsweredExamQuestions(x.readingWritingTestModule2.id)
                  }
                  class="btn btn-light"
                >
                  Check Exam
                </button>
              </td>
            </tr>
            <tr>
              <td>Mathematics Module 1</td>
              <td>{x.mathematicsTestModule1.totalRightAnswers}/27</td>
              <td>{x.mathematicsTestModule1.totalWrongAnswers}/27</td>
              <td>{x.mathematicsTestModule1.totalBlankAnswers}/27</td>
              <td>
                <button
                  class="btn btn-light"
                  onClick={() => {
                    getAnsweredExamQuestions(x.mathematicsTestModule1.id);
                  }}
                >
                  Check Exam
                </button>
              </td>
            </tr>
            <tr>
              <td>Mathematics Module 2</td>
              <td>{x.mathematicsTestModule2.totalRightAnswers}/27</td>
              <td>{x.mathematicsTestModule2.totalWrongAnswers}/27</td>
              <td>{x.mathematicsTestModule2.totalBlankAnswers}/27</td>
              <td>
                <button
                  class="btn btn-light"
                  onClick={() => {
                    getAnsweredExamQuestions(x.mathematicsTestModule2.id);
                  }}
                >
                  Check Exam
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <p>Reading Score: {x.readingScore} / 800</p>
        <p>Mathematics Score: {x.mathScore} / 800</p>
        <p>Total Score: {x.totalScore} / 1600</p>
      </div>
    </div>
  );
};

export default StudentSATResultDetails;
