import { Fragment, useState } from "react"
import { useSelector } from "react-redux"


const QuestionsPreview = (props) => {
 
  const [index, setIndex ] = useState(-1)
  const [questions, setQuestions] = useState(props.questions.questionDtos) ;
  const [questionExample, setQuestionExample] = useState({})

  console.log(questions)


    return (


<Fragment>
    {props.questions.questionDtos.map((question) => {
        
        let value = <button onClick={(event) => {
          event.preventDefault(); 
          setQuestionExample(props.questions.questionDtos[props.questions.questionDtos.indexOf(question)]);
          setIndex(props.questions.questionDtos.indexOf(question));
        }} className={`qbutton 
        `}>{props.questions.questionDtos.indexOf(question) + 1}</button>

      
          
        if(props.questions.questionDtos.indexOf(question) % 20 === 0){
          return ( <Fragment>
            <br></br> {value}
            </Fragment>)
        }
        else{
          return value;
        }
       
      })
    }

{ index > -1 &&
<Fragment>
          <div className="questionContainer clearfix">
            <div className="questionText">
              <img
                className="questionTextImage"
                src={questionExample.references["QuestionText"]}
              ></img>
              <p>{questionExample.question.questionText}</p>
            </div>
            <div className="questionAnswers clearfixchild">
              {questions[index].question.questionType === 'Test' ?               <div>
                <div
                  className={`potentialAnswerDiv `}
                >
                  {typeof questionExample.references["Answer1"] ===
                  "undefined" ? (
                    ""
                  ) : (
                    <img
                      className="potentialAnswerImage"
                      src={questionExample.references["Answer1"]}
                    ></img>
                  )}
                  <p>{questionExample.question.potentialAnswers[0].answer}</p>
                </div>

                <div
                  
                  className={`potentialAnswerDiv ${
                    questions[index].selected === "B" && "selected "
                  }`}
                >
                  {typeof questionExample.references["Answer2"] ===
                  "undefined" ? (
                    ""
                  ) : (
                    <img
                      className="potentialAnswerImage"
                      src={questionExample.references["Answer2"]}
                    ></img>
                  )}
                  <p>{questionExample.question.potentialAnswers[1].answer}</p>
                </div>

                <div
                  className={`potentialAnswerDiv ${
                    questions[index].selected === "C" && "selected "
                  }`}
                >
                  {typeof questionExample.references["Answer3"] ===
                  "undefined" ? (
                    ""
                  ) : (
                    <img
                      className="potentialAnswerImage"
                      src={questionExample.references["Answer3"]}
                    ></img>
                  )}
                  <p>{questionExample.question.potentialAnswers[2].answer}</p>
                </div>

                <div
                  className={`potentialAnswerDiv ${
                    questions[index].selected === "D" && "selected "
                  }`}
                >
                  {typeof questionExample.references["Answer4"] ===
                  "undefined" ? (
                    ""
                  ) : (
                    <img
                      className="potentialAnswerImage"
                      src={questionExample.references["Answer4"]}
                    ></img>
                  )}
                  <p>{questionExample.question.potentialAnswers[3].answer}</p>
                </div>
              </div> : ''}

              {questions[index].question.questionType === 'Open-Ended' ? <Fragment>
                <p>{questions[index].question.correctAnswer.answer}</p>
              </Fragment>: ''}

            </div>
          </div>

        </Fragment>
        }
        <br></br>
        <button onClick={() => {props.deleteQuestion(questions[index].question.id)}}>Delete Question</button>

</Fragment>


     

)

}

export default QuestionsPreview