import { Link, useLocation } from "react-router-dom";
import classes from "./QuizResult.module.css";


const QuizResult = () => {

    const loc = useLocation();

    console.log(loc.pathname); // result: '/secondpage'

    const d = loc.data.data;
    console.log(d);

    let skordata = 'Skorunuz : '.concat(d.score);
    console.log(Object.values(d));


return (
    <div class="containerFill">
        <p>Quiz Result Screen</p><br></br>
        <p> {skordata} </p>

        <br>
        </br>

    <Link to="/Index">Back To Menu</Link>


        
    </div>
)
}
export default QuizResult;