import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./QuestionForm.css";
import storage from "../../firebase/FirebaseStorage";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { async } from "@firebase/util";
import Modal from "../UI/Modal";
import CreateSAT from "../CreateSAT/CreateSAT";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import "react-notifications/lib/notifications.css";

const QuestionForm = () => {
  const history = useHistory();

  const [reset, setReset] = useState(0);
  const [extraAnswers, setExtraAnswers] = useState(["", ""]);
  const [hasEnoughAnswers, setHasEnoughAnswers] = useState(false);
  //const [topics, setTopics] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [questionType, setQuestionType] = useState("Test");
  const [subjectTopics, setSubjectTopics] = useState([]);
  const [selectedSubjectIndex, setSelectedSubjectIndex] = useState(-1);
  const [selectedTopicIndex, setSelectedTopicIndex] = useState(0);
  const [isBusy, setIsBusy] = useState(false);

  const [questionTextImage, setQuestionTextImage] = useState("");
  const [answer1Image, setAnswer1Image] = useState("");
  const [answer2Image, setAnswer2Image] = useState("");
  const [answer3Image, setAnswer3Image] = useState("");
  const [answer4Image, setAnswer4Image] = useState("");

  const [modalShown, setModalShown] = useState(false);

  const [Exams, setExams] = useState([]);

  const id = useSelector((state) => state.auth.userid);

  const [questionTextFirebase, setQuestionTextFirebase] = useState("");
  const [answer1Firebase, setAnswer1Firebase] = useState("");
  const [answer2Firebase, setAnswer2Firebase] = useState("");
  const [answer3Firebase, setAnswer3Firebase] = useState("");
  const [answer4Firebase, setAnswer4Firebase] = useState("");

  console.log(selectedTopicIndex);

  console.log("Firebase:");
  console.log(questionTextFirebase);

  const [questionImage, setQuestionImage] = useState();

  let d = 0;

  const AddQuestionFirebaseReference = (
    id,
    bucket,
    downloadUrl,
    path,
    refOf
  ) => {
    const firebaseReference = {
      questionId: id,
      bucket: bucket,
      downloadUrl: downloadUrl,
      path: path,
      referenceOf: refOf,
    };

    console.log(firebaseReference);

    const response = fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/Firebase/Add",
      {
        method: "POST",
        body: JSON.stringify(firebaseReference),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = response.json();
  };

  const createNotification = (type) => {
    return () => {
      switch (type) {
        case "info":
          NotificationManager.info("Info message");
          break;
        case "success":
          NotificationManager.success("Successfully Added Question", "");
          break;
        case "warning":
          NotificationManager.warning(
            "Warning message",
            "Close after 3000ms",
            3000
          );
          break;
        case "error":
          NotificationManager.error("Error message", "Click me!", 5000, () => {
            alert("callback");
          });
          break;
      }
    };
  };

  const createNotification2 = () => {
    return NotificationManager.success("Successfully Added Question", "");
  };

  const UploadImageToFirebase = async (id, type, image) => {
    if (questionTextImage === null) {
      return;
    }
    const imageRef = ref(storage, `images/Sorular/${id}/${type}`);

    console.log("Started Upload");

    let snap = "";
    let u = "";

    await uploadBytes(imageRef, image).then(async (snapshot) => {
      console.log(snapshot);
      snap = snapshot;
      await getDownloadURL(snapshot.ref).then((url) => {
        u = url;
      });
    });
    console.log(u);
    console.log("Finished Upload");
    console.log("Adding Reference To Database");
    console.log(snap.metadata.bucket);
    console.log(u);
    console.log(snap.metadata.fullPath);
    console.log(id);

    await AddQuestionFirebaseReference(
      parseInt(id),
      snap.metadata.bucket,
      u,
      snap.metadata.fullPath,
      type
    );
    console.log("Finished");
  };

  const getSubjectsAndTopics = async () => {
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/teachersubjects",
      {
        method: "POST",
        body: JSON.stringify(id),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();

    const SubjectTopics = [];
    for (let i = 0; i < da.length; i++) {
      let subject = {
        id: da[i].id,
        name: da[i].name,
        topics: da[i].topics,
      };
      SubjectTopics.push(subject);
    }
    setSubjects(SubjectTopics);
  };

  const getSubTopics = (event) => {
    console.log(event.target.value);
  };

  const getSATTests = async () => {
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/getsattests",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();
    console.log("SAT TESTS");
    console.log(da);
    setExams(da);
  };

  const getSubjectTopics = async () => {
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/getsubjectdata",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();
    console.log("Topics");
    console.log(da);
    setSubjectTopics(da);
  };

  useEffect(() => {
    getSubjectsAndTopics();
    getSATTests();
    getSubjectTopics();
    console.log("Subjects");
  }, []);

  const sendQuestion = async (event) => {
    event.preventDefault();

    let data = {
      questionText: event.target.questionText.value,
      potentialAnswers: [],
      correctAnswer: {
        id: 0,
        Answer: event.target.correctAnswer.value,
      },
      subject: { name: event.target.subject.value }, //subjects[event.target.subject.options.selectedIndex - 1],
      topic: subjectTopics[selectedSubjectIndex].topics[selectedTopicIndex],
      subtopic:
        subjectTopics[selectedSubjectIndex].topics[selectedTopicIndex]
          .subTopics[event.target.subTopics.options.selectedIndex],
      owner: { id: id },
      questionType: event.target.questionType.value,
    };
    if (questionType === "Test") {
      data.potentialAnswers.push({
        id: 0,
        Answer: event.target.Answer1.value,
      });
      data.potentialAnswers.push({
        id: 0,
        Answer: event.target.Answer2.value,
      });
      data.potentialAnswers.push({
        id: 0,
        Answer: event.target.Answer3.value,
      });
      data.potentialAnswers.push({
        id: 0,
        Answer: event.target.Answer4.value,
      });
      event.target.Answer1.value = "";
      event.target.Answer2.value = "";
      event.target.Answer3.value = "";
      event.target.Answer4.value = "";
    } else {
      data.potentialAnswers.push({
        id: 0,
        Answer: event.target.correctAnswer.value,
      });
    }
    const SATTest = Exams[event.target.SATTest.options.selectedIndex];

    const object = {
      sATTestID: SATTest.id,
      testType: event.target.subject.value,
      question: data,
    };

    console.log(data);

    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/questionadd",
      {
        method: "POST",
        body: JSON.stringify(object),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const da = await response.json();
    console.log(da);
    console.log(da.success);

    if (da.success === true) {
      if (questionTextFirebase != "") {
        console.log("Uploading Question Text To Firebase");
        UploadImageToFirebase(da.message, "QuestionText", questionTextFirebase);
      }
      if (data.questionType === "Test") {
        if (answer1Firebase != "") {
          console.log("Uploading Answer1 Image To Firebase");
          UploadImageToFirebase(da.message, "Answer1", answer1Firebase);
        }
        if (answer2Firebase != "") {
          console.log("Uploading Answer2 Image To Firebase");
          UploadImageToFirebase(da.message, "Answer2", answer2Firebase);
        }
        if (answer3Firebase != "") {
          console.log("Uploading Answer3 Image To Firebase");
          UploadImageToFirebase(da.message, "Answer3", answer3Firebase);
        }
        if (answer4Firebase != "") {
          console.log("Uploading Answer4 Image To Firebase");
          UploadImageToFirebase(da.message, "Answer4", answer4Firebase);
        }
      }

      createNotification2();

      console.log("reset");

      //Reset
      setExtraAnswers(["", ""]);
      setHasEnoughAnswers(false);

      setSubjects([]);
      setQuestionType("Test");

      setQuestionTextImage("");
      setAnswer1Image("");
      setAnswer2Image("");
      setAnswer3Image("");
      setAnswer4Image("");

      setModalShown(false);
      setQuestionTextFirebase("");
      setAnswer1Firebase("");
      setAnswer2Firebase("");
      setAnswer3Firebase("");
      setAnswer4Firebase("");

      console.log("Redirect");
      //history.push("./Menu");
    } else {
      console.log("Başarısız Deneme İçin Notification Entegre Edilecek");
    }
  };

  const getTopics = (event) => {
    console.log(subjectTopics);
    console.log(event.target.value);
    if (event.target.options.selectedIndex > 0) {
      if (event.target.value.includes("Reading")) {
        setSelectedSubjectIndex(0);
        console.log("Reading");
      } else if (event.target.value.includes("Mathematics")) {
        setSelectedSubjectIndex(1);
        console.log("Math");
      }
    }
    if (event.target.value === "") {
      // setTopics([]);
    }
  };

  const addNewQuestion = () => {
    setExtraAnswers([...extraAnswers, ""]);
  };

  if (reset === 0) {
    return (
      <div>
        {modalShown === true && (
          <Modal
            action={() => {
              setModalShown(false);
            }}
            onHideCart={() => {
              setModalShown(false);
            }}
          >
            <CreateSAT
              hideModal={() => {
                setModalShown(false);
              }}
              updateQuiz={getSATTests}
            ></CreateSAT>
          </Modal>
        )}

        <form onSubmit={sendQuestion}>
          <div className="section">
            <label>Question Text : </label>
            <br />
            <br></br>
            {questionTextImage === "" ? (
              ""
            ) : (
              <img src={questionTextImage} className="img"></img>
            )}

            <input
              type="file"
              onChange={(event) => {
                const x = URL.createObjectURL(event.target.files[0]);
                setQuestionTextImage(x);
                console.log(x);
                console.log(event.target.files[0]);
                console.log(questionTextFirebase);
                setQuestionTextFirebase(event.target.files[0]);
                console.log(questionTextFirebase);
                return () => {
                  URL.revokeObjectURL(x);
                  console.log(questionTextFirebase);
                };
              }}
            ></input>
            <br></br>
            <textarea
              rows="5"
              name="questionText"
              className="textInput"
            ></textarea>
          </div>
          <br />

          <select
            name="questionType"
            onChange={(event) => {
              setQuestionType(event.target.value);
              console.log(event.target.value);
            }}
          >
            <option selected>Test</option>
            <option>Open-Ended</option>
          </select>

          <br></br>
          {questionType === "Open-Ended" ? (
            <Fragment>
              <label>Correct Answer</label>
              <br />
              <input
                name="correctAnswer"
                className="formInput"
                type="text"
              ></input>
              <br></br>
            </Fragment>
          ) : (
            ""
          )}

          {questionType === "Test" ? (
            <Fragment>
              <label>Potential Answers:</label>
              <br />
              <br />

              <div className="potAnswerDiv">
                <label>A : </label>
                <br />
                {answer1Image === "" ? (
                  ""
                ) : (
                  <img src={answer1Image} className="img"></img>
                )}
                <input
                  type="file"
                  onChange={(event) => {
                    const x = URL.createObjectURL(event.target.files[0]);
                    console.log(x);
                    setAnswer1Image(x);
                    setAnswer1Firebase(event.target.files[0]);
                    return () => {
                      URL.revokeObjectURL(x);
                    };
                  }}
                ></input>
                <br></br>
                <input type="text" className="textInput" name="Answer1"></input>
              </div>
              <div className="potAnswerDiv">
                <label>B : </label>
                <br />
                {answer2Image === "" ? (
                  ""
                ) : (
                  <img src={answer2Image} className="img"></img>
                )}
                <input
                  type="file"
                  onChange={(event) => {
                    const x = URL.createObjectURL(event.target.files[0]);
                    console.log(x);
                    setAnswer2Image(x);
                    setAnswer2Firebase(event.target.files[0]);
                    return () => {
                      URL.revokeObjectURL(x);
                    };
                  }}
                ></input>
                <br></br>
                <input type="text" className="textInput" name="Answer2"></input>
              </div>
              <div className="potAnswerDiv">
                <label>C : </label>
                <br />
                {answer3Image === "" ? (
                  ""
                ) : (
                  <img src={answer3Image} className="img"></img>
                )}
                <input
                  type="file"
                  onChange={(event) => {
                    const x = URL.createObjectURL(event.target.files[0]);
                    console.log(x);
                    setAnswer3Image(x);
                    setAnswer3Firebase(event.target.files[0]);
                    return () => {
                      URL.revokeObjectURL(x);
                    };
                  }}
                ></input>
                <br></br>
                <input type="text" className="textInput" name="Answer3"></input>
              </div>
              <div className="potAnswerDiv">
                <label>D : </label>
                <br />
                {answer4Image === "" ? (
                  ""
                ) : (
                  <img src={answer4Image} className="img"></img>
                )}
                <br></br>
                <input
                  type="file"
                  onChange={(event) => {
                    const x = URL.createObjectURL(event.target.files[0]);
                    console.log(x);
                    setAnswer4Image(x);
                    setAnswer4Firebase(event.target.files[0]);
                    event.target.files = null;
                    event.target.value = "";
                    return () => {
                      URL.revokeObjectURL(x);
                    };
                  }}
                ></input>
                <br></br>
                <input type="text" className="textInput" name="Answer4"></input>
              </div>

              <label>Correct Answer</label>
              <br />
              <select name="correctAnswer" className="formInput">
                <option>A</option>
                <option>B</option>
                <option>C</option>
                <option>D</option>
              </select>
              <br></br>
            </Fragment>
          ) : (
            ""
          )}

          <label>Dersler</label>
          <br />
          <select name="subject" onChange={getTopics} className="formInput">
            <option></option>
            <option>Reading Module 1</option>
            <option>Reading Module 2</option>
            <option>Mathematics Module 1</option>
            <option>Mathematics Module 2</option>
          </select>

          <br />
          <Fragment>
            <label>Topic</label>
            <br />
            <select
              name="topics"
              className="formInput"
              onChange={(event) => {
                setSelectedTopicIndex(event.target.options.selectedIndex);
              }}
            >
              {selectedSubjectIndex !== -1
                ? subjectTopics[selectedSubjectIndex].topics.map((topic) => {
                    return (
                      <option key={topic.id} value={topic.id}>
                        {topic.name}
                      </option>
                    );
                  })
                : ""}
            </select>
            <br />
          </Fragment>

          <Fragment>
            <label>SubTopics</label>
            <br />
            <select name="subTopics" className="formInput">
              {selectedSubjectIndex !== -1
                ? subjectTopics[selectedSubjectIndex].topics[
                    selectedTopicIndex
                  ].subTopics.map((topic) => {
                    return (
                      <option key={topic.id} value={topic.id}>
                        {topic.name}
                      </option>
                    );
                  })
                : ""}
            </select>
            <br />
          </Fragment>

          <label>SAT Test</label>
          <br />
          <select name="SATTest" className="formInput">
            {Exams.map((exam) => {
              return <option key={exam.id}>{exam.testName}</option>;
            })}
          </select>
          <br />

          <input type="submit" className="inputButton"></input>
        </form>

        <br></br>

        <button class="btn btn-primary" onClick={() => setModalShown(true)}>
          Add New SAT Test
        </button>

        <br></br>
        <br></br>
        
        <button class="btn btn-primary" onClick={() => history.push("./Index")}>
          Back To Menu
        </button>
        <NotificationContainer />
      </div>
    );
  } else {
    <p>Loading...</p>;
  }
};

export default QuestionForm;
