import { async } from "@firebase/util";

const { Fragment, useState } = require("react");

const StudentAdd = () => {
  const [isAdded, setIsAdded] = useState(0);
  const [password, setPassword] = useState("");

  const addStudent = async (event) => {
    event.preventDefault();

    console.log(event.target.fullname.value);

    const pkg = {
      fullName: event.target.fullname.value,
    };

    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/addStudent",
      {
        method: "POST",
        body: JSON.stringify(pkg),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const da = await response.json();

    console.log(da);
    setPassword(da.password);

    setIsAdded(1);
  };

  return (
    <Fragment>
      <div class="container">
        <form onSubmit={addStudent}>

          
          <div class="centeredDiv">

           
            <div >
              <label>Full Name: </label>
              <input type="text" class="form-control" name="fullname"></input>
            </div>
            <br></br>
            <input type="submit"></input>
          </div>
        </form>
        <br></br>
        {isAdded === 1 && (
          <Fragment>
            <label>Password</label>
            <p>{password}</p>
          </Fragment>
        )}
      </div>
    </Fragment>
  );
};

export default StudentAdd;
