import { useState } from "react";

const TeacherAddForm = () => {
  const [Error, setError] = useState("");
  const [Password, setPassword] = useState('')

  const addteacher = async (event) => {
    event.preventDefault()
    setError('')
    setPassword('')

    const pkg = {
        fullName: event.target.tfullname.value,
        username: event.target.tusername.value,
      };
  
      const response = await fetch(
        "https://webapplication120221210090459.azurewebsites.net/api/addTeacher",
        {
          method: "POST",
          body: JSON.stringify(pkg),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const da = await response.json();
  
      console.log(da);
      
    if (da.success === false){
        setError(da.message)
    }
    else{
        setPassword(da.message)
    }


  } 

  return (
    <div class="container">
        {Error === '' ? <br/> : <p>{Error} </p> }
      
      <form onSubmit={addteacher}>
        <div class="mb-3">
          <label class="mb-3">Teacher Full Name</label>
          <input type="text" class="form-control" name='tfullname'></input>
        </div>
        <div class="mb-3">
            <label class="mb-3">Teacher Username</label>
            <input type="text" class="form-control" name='tusername'></input>
        </div>
        <input type="submit" value="Submit" class="btn btn-primary"></input>
      </form>
      {Password === '' ? <br/> : <p>{Password} </p> }
    </div>
  );
};

export default TeacherAddForm;
