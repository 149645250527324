import React from 'react';
import { useState, useEffect } from 'react';

const Timer = (props) => {
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  let deadline = "December, 25, 2022";

  function getDeadLine(minutes) {
    let date = Date.now();
    return new Date(date + minutes*60000);
  }

  deadline=getDeadLine(props.duration)

  const getTime = () => {
    const time = Date.parse(deadline) - Date.now();

    setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
    setMinutes(Math.floor((time / 1000 / 60) % 60));
    setSeconds(Math.floor((time / 1000) % 60));
    if(time <= 0){
        props.TimeUp();
    }
  };


  

  useEffect(() => {
    const interval = setInterval(() => getTime(deadline), 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="timer">
        <p>{hours}:{minutes}:{seconds}</p>
    </div>
  );
};

export default Timer;