import { useHistory } from "react-router-dom";
import "./Selection.css";

const Selection = () => {
  const history = useHistory();

  return (
    <div className="container">
      <div className="BoxContainer">
        <div class="d-grid gap-2 col-8 mx-auto">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">SAT Exam</h5>
              <p class="card-text">SAT ile ilgili kullanılabilecek modüller:</p>
            </div>
            <div class="card-body">
              <div class="d-grid gap-2 col-6 mx-auto">
                <button
                  class="btn btn-primary"
                  type="button"
                  onClick={() => history.push("./SATOverview")}
                >
                  Start SAT Test
                </button>
                <button
                  class="btn btn-primary"
                  type="button"
                  onClick={() => history.push("./StudentResults")}
                >
                  SAT Results
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">SAT</h5>
        <p class="card-text">SAT Modülümüze Ulaşmak İçin Tıklayınız.</p>
        <a href="#" class="btn btn-primary">Go somewhere</a>
      </div>
    </div>
  </div>
  <div class="col-sm-6">
    <div class="card">
      <div class="card-body">
        <h5 class="card-title">IB</h5>
        <p class="card-text">IB Question Bank Projemiz Geliştirme Aşamasındadır.</p>
        
      </div>
    </div>
  </div>
</div>
        <div
          className="Box1"
          onClick={() => {
            history.push("./Login");
          }}
        >
          <div className="box">
            <h1 className="headerContents">SAT</h1>
          </div>
        </div>
        <div className="Box2">
          <div className="box">
            <h1 className="headerContents">AP</h1>
          </div>
        </div>
      </div>

      <div className="BoxContainer">
        <div className="Box3">
          <div className="box">
            <h1 className="headerContents">GMAT</h1>
          </div>
        </div>
        <div className="Box4">
          <div className="box">
            <h1 className="headerContents">IB</h1>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Selection;
