import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import "./Menu.css";
import QuizCategorySelectForm from "./QuizCategorySelectForm";
import Modal from "./UI/Modal";
import { authActions } from "../store/authContext";
import TeacherAddForm from "./TeacherAddForm/TeacherAddForm";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import CreateSessionForStudent from "../CreateSessionForStudent/CreateSessionForStudent";
const Menu = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const role = useSelector((state) => state.auth.role);
  const [modalShown, setModalShown] = useState(false);
  const [teacherAddModalShown, setTeacherAddModalShown] = useState(false);
  const [examSessionForStudentModal, setExamModal] = useState(false);
  const [error, setError] = useState(false);
  const [result, setResult] = useState("");

  const showQuizSetup = () => {
    setModalShown(true);
  };

  const hideQuizSetup = () => {
    setModalShown(false);
  };

  const startQuiz = (da) => {
    hideQuizSetup();
    console.log(da);
    history.push({ pathname: "./Test", package: { da } });
  };

  const forwardToSATTest = (satid) => {
    history.push({ pathname: "./SATOverview", package: { satid } });
  };

  const logOut = () => {
    dispatch(authActions.logout({}));
    history.push("./Login");
  };

  return (
    <div className="containerFill">
      {role === "Student" && modalShown === true && (
        <Modal action={startQuiz} onHideCart={hideQuizSetup}>
          <QuizCategorySelectForm action={startQuiz}></QuizCategorySelectForm>
        </Modal>
      )}
      {role === "Student" && (
        <Fragment>
          <div class="d-grid gap-2 col-6 mx-auto">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">SAT Exam</h5>
                <p class="card-text">
                  SAT ile ilgili kullanılabilecek modüller:
                </p>
              </div>
              <div class="card-body">
                <div class="d-grid gap-2 col-6 mx-auto">
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => history.push("./SATOverview")}
                  >
                    Start SAT Test
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => history.push("./StudentResults")}
                  >
                    SAT Results
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {role === "Teacher" && (
        <Fragment>
          <div class="d-grid gap-2 col-6 mx-auto">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">SAT Exam</h5>
                <p class="card-text">
                  SAT ile ilgili kullanılabilecek modüller:
                </p>
              </div>
              <div class="card-body">
                <div class="d-grid gap-2 col-6 mx-auto">
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => history.push("./QuestionAdd")}
                  >
                    Add Question
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => history.push("./SATPreview")}
                  >
                    SAT Preview
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
      <br></br>

      {(role === "Teacher" || role === "Admin") && (
        <Fragment>
          <div class="d-grid gap-2 col-6 mx-auto">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Öğrenciler</h5>
                <p class="card-text">
                  Öğrenciler ile ilgili kullanılabilecek modüller:
                </p>
              </div>
              <div class="card-body">
                <div class="d-grid gap-2 col-6 mx-auto">
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => history.push("./TeacherStudents")}
                  >
                    Öğrenciler
                  </button>
                  {role === "Admin" && (
                    <button
                      class="btn btn-primary"
                      type="button"
                      onClick={() => history.push("./StudentAdd")}
                    >
                      Öğrenci Ekle
                    </button>
                  )}

                  {role === "Admin" && (
                    <button
                      class="btn btn-primary"
                      onClick={() => setExamModal(true)}
                    >
                      Öğrenciye SAT Sınavı Tanımla
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      <br></br>
      {role === "Admin" && (
        <Fragment>
          <div class="d-grid gap-2 col-6 mx-auto">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Öğretmenler</h5>
                <p class="card-text"></p>
              </div>
              <div class="card-body">
                <div class="d-grid gap-2 col-6 mx-auto">
                  <button class="btn btn-primary" type="button">
                    Öğretmenler
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    onClick={() => setTeacherAddModalShown(true)}
                  >
                    Öğretmen Ekle
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}

      {teacherAddModalShown == true && (
        <Modal
          onHideCart={() => {
            setTeacherAddModalShown(false);
            setError(false);
          }}
        >
          <TeacherAddForm></TeacherAddForm>
        </Modal>
      )}
      {examSessionForStudentModal == true && (
        <Modal
          onHideCart={() => {
            setExamModal(false);
          }}
        >
          <CreateSessionForStudent></CreateSessionForStudent>
        </Modal>
      )}
      <div class="container">
        <p>
          Veritas Academy olarak Digital SAT için güncel sınav sistemine uygun
          yepyeni bir portal geliştirmekteyiz. Web sitemizden SAT denemelerimizi
          çözdükçe bizlere görüş ve önerilerinizi de iletmenizi rica eder, iyi
          çalışmalar dileriz.
        </p>
      </div>

      <br />
      <button className="btn btn-secondary" onClick={logOut}>
        Logout
      </button>
    </div>
  );
};

export default Menu;
