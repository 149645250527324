import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./SATSelection.css";
const SATSelection = (props) => {
  const User = useSelector((state) => state.auth);

  console.log(User.role);
  const [exams, setExams] = useState([]);
  console.log(typeof User.userid);

  const getSATTests = async () => {



    if (User.role === "Teacher") {
      console.log('Teacher request')
      const response = await fetch(
        "https://webapplication120221210090459.azurewebsites.net/api/getsattests",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const da = await response.json();
      console.log(da);
      setExams(da);
    } 
    else {
      const response = await fetch(
        "https://webapplication120221210090459.azurewebsites.net/api/getuserincompleteexamsessions",
        {
          method: "POST",
          body: JSON.stringify(User.userid),
          headers: {
            "Content-Type": "application/json",
          },
        }
        
      );
      const da = await response.json();
      console.log(da);
      setExams(da);

    }

  };

  console.log(exams);
  useEffect(() => {
    getSATTests();
  }, []);

  const submitQuizRequest = (event) => {
    event.preventDefault();
    props.startSAT(exams[event.target.exam.options.selectedIndex]);
  };

  if (User.role === 'Student'){

 
  return (
    <form onSubmit={submitQuizRequest} className="dropdown">
      <select name="exam">
        {exams.map((exam) => {
          return <option>{exam.test.testName}</option>;
        })}
      </select>
      <input type="submit" className="inputButton"></input>
    </form>
  );
}

  else{
    return (
      <form onSubmit={submitQuizRequest} className="dropdown">
      <select name="exam">
        {console.log(exams)}
        {exams.map((exam) => {
          return <option>{exam.testName}</option>;
        })}
      </select>
      <input type="submit" className="inputButton"></input>
    </form>
    )
  }



};

export default SATSelection;
