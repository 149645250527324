import React, { Fragment, useCallback, useEffect, useState } from "react";
import Question from "./Question";
import { useHistory, Link, useLocation } from "react-router-dom";
import "./Questions.css";
import { useSelector } from "react-redux";
import Timer from "./Timer/Timer";

const Questions = (props) => {
  let history = useHistory();
  let location = useLocation();

  const User = useSelector((state) => state.auth);
  const no = 1;

  const q = location.package.da;

  const [questionNo, setQuestionNo] = useState(1);
  const [isLoading, setLoading] = useState(true);
  const [quizQuestions, setQuestions] = useState([]);

  const fetchHandler = useCallback(async () => {
    const data = await getAnswer();
    setQuestions(data);
  }, []);

  const geturl = "https://examxtestapi.azurewebsites.net/api/SendQuestions";

  const getAnswer = async () => {
    let count = 0;

    /*const response = await fetch('https://localhost:5001/api/SendQuestions',{
      method: 'POST',
      body: JSON.stringify(location.package.cat),
      headers: {
        'Content-Type' : 'application/json'
      }
    });
    const da = await response.json();
*/
    if(typeof(q[0].references['Answer1']) == 'undefined'){
      console.log('NULLL')
    }
    for (let x = 1; x <= q.length; x++) {
      q[x - 1].questionNumber = x;
    }
    const data = await q.map((obj) => ({
      QuestionNumber: obj.question.questionNumber,
      QuestionTextImageUrl: obj.references['QuestionText'],
      QuestionText: obj.question.questionText,
      Answer1: obj.question.potentialAnswers[0].answer,
      Answer1Image: obj.references['Answer1'],
      Answer2: obj.question.potentialAnswers[1].answer,
      Answer2Image: obj.references['Answer2'],
      Answer3: obj.question.potentialAnswers[2].answer,
      Answer4: obj.question.potentialAnswers[3].answer,

      CorrectAnswer: obj.question.correctAnswer,
      Answer3Image: obj.references['Answer3'],
      Answer4Image: obj.references['Answer4'],
      Selected: null,
    }));


    data.SubjectID = q.Subject;
    console.log('DATA')
    return data;
  };

  const nextQuestionClickHandler = () => {
    setQuestionNo(questionNo + 1);
  };

  const previousQuestionClickHandler = () => {
    if (questionNo !== 1) {
      setQuestionNo(questionNo - 1);
    }
  };

  const selectQuestionByNumber = (event) => {
    setQuestionNo(parseInt(event.target.innerText));
  };

  const updateItemState = (questionNumber, selectedValue) => {
    let updatedList = quizQuestions.map((item) => {
      if (item.QuestionNumber === questionNumber) {
        item.Selected = selectedValue;
      }
      return item; // else return unmodified item
    });
    setQuestions(updatedList); // set state to new object with updated list
  };

  const setSelected = (no, selectedValue) => {
    let question = quizQuestions.find((q) => q.QuestionNumber === no);
    updateItemState(no, String(selectedValue));
  };

  useEffect(() => {
    fetchHandler();
  }, [fetchHandler]);

  const submitQuiz = async () => {
    const quizData = {
      quizQuestions: quizQuestions,
      user: { ID: User.userid },
      SubjectID: q.Subject,
    };
    const response = await fetch("https://localhost:5001/api/send", {
      method: "POST",
      body: JSON.stringify(quizData),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    history.push({ pathname: "./QuizResult", data: { data } });
  };

  const finishQuiz = () => {
    submitQuiz();
  };

  {
    if (quizQuestions.length < 1) {
      return <h1>...Loading</h1>;
    }
  }

  {
    if (questionNo > quizQuestions.length) {
      return (
        <div className="container">
          <h1>Sinavi Bitirdiniz</h1>
          <button className="button" onClick={submitQuiz}>
            Submit Quiz
          </button>
          <br></br>
          <button className="button" onClick={previousQuestionClickHandler}>
            Previous Question
          </button>
          <Link to="/Index">Back to Menu</Link>
        </div>
      );
    }
  }
  return (
    <div className="container">
      <Timer duration={100} TimeUp={finishQuiz}></Timer>
      <p>All Questions</p>
      {quizQuestions.map((obj) => {
        {
          if (quizQuestions.indexOf(obj) % 5 === 0) {
            return (
              <Fragment>
                <br></br>
                <button
                  onClick={selectQuestionByNumber}
                  className={`questionNo ${
                    questionNo === quizQuestions.indexOf(obj) + 1
                      ? "selected"
                      : obj.Selected !== null
                      ? "answered"
                      : ""
                  }`}
                >
                  {quizQuestions.indexOf(obj) + 1}
                </button>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <button
                  onClick={selectQuestionByNumber}
                  className={`questionNo ${
                    questionNo === quizQuestions.indexOf(obj) + 1
                      ? "selected"
                      : obj.Selected !== null
                      ? "answered"
                      : ""
                  }`}
                >
                  {quizQuestions.indexOf(obj) + 1}
                </button>
              </Fragment>
            );
          }
        }
      })}

      {quizQuestions.length > 0 ? (
        <Question
          item={quizQuestions[questionNo - 1]}
          NextQuestion={nextQuestionClickHandler}
          PreviousQuestion={previousQuestionClickHandler}
          onSelect={setSelected}
          previousQuestionClickHandler={previousQuestionClickHandler}
          nextQuestionClickHandler={nextQuestionClickHandler}
        ></Question>
      ) : (
        <p>An Error Occured</p>
      )}
    </div>
  );
};

export default Questions;
