import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";


const StudentQuizCheck = () => {

    let location = useLocation();
    const id = location.package.id;
    console.log(id);

    let soruNo = 0;

    const[result, setResults] = useState({});
    

    const getStudentQuiz = async (quizid) => {
        const response = await fetch("https://webapplication120221210090459.azurewebsites.net/api/getstudentquizresult", {
            method: "POST",
            body: JSON.stringify(quizid),
            headers: {
              "Content-Type": "application/json",
            },
          });
        
          const da = await response.json();
          setResults(da);
    } 

    useEffect(() => {getStudentQuiz(id)},[])


    return (
        <Fragment>
            {console.log(result)}
            <p>Skor: {result.score}</p>
            {result.answeredQuestions.map((q) => {
                soruNo++;
                return(
                <Fragment>
                    <p><b>{soruNo}. Soru</b></p>
                    <p>Soru: {q.questionText}</p>
                    <p>Doğru Cevap: {q.correctAnswer}</p>
                    <p>Seçilmiş Cevap: {q.selected}</p>
                </Fragment> )

            })}

        </Fragment>
    )
}

export default StudentQuizCheck;