import { Fragment, useEffect, useState } from "react";
import SATSelection from "../SATSelectionModal/SATSelection";
import Modal from "../UI/Modal";
import QuestionsPreview from "./QuestionsPreview";
import "../SAT/ReadingTest/ReadingTest.css";
import "../Menu.css"

const ExamQuestionsPreview = () => {
  const [showModal, setShowModal] = useState(true);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [selectedModule, setSelectedModule] = useState("");
  const [questions, setQuestions] = useState([]);
  const [satTestID, setSatTestID] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pkg, setPkg] = useState();

  const setSAT = (quizID) => {
    setSatTestID(quizID);
    setShowModal(false);
  };

  const selectModule = async (TestType) => {
    setIsLoading(true);
    let x = await getQuestions(TestType);
    setPkg(x);
    setIsLoading(false);
    setSelectedModule(TestType);
  };

  const deleteQuestion = async (id) => {
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/question/delete",
      {
        method: "POST",
        body: JSON.stringify(id),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const da = await response.json();
    selectModule(selectedModule);
  };

  const getQuestions = async (TestType) => {
    const req = {
      examId: satTestID.id,
      testType: TestType,
    };

    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/getquiz",
      {
        method: "POST",
        body: JSON.stringify(req),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const da = await response.json();
    console.log(da)
    return da;
  };

  if (showModal === true) {
    return (
      <Modal>
        <SATSelection startSAT={setSAT}></SATSelection>
      </Modal>
    );
  }

  return (
    <div classname="container">
      <div className="d-grid gap-2 col-4 mx-auto">
        <button
          className="btn btn-primary btn-lg btn-block"
          onClick={() => {
            selectModule("Reading Module 1");
          }}
        >
          Reading Module 1
        </button>
        <button className="btn btn-primary btn-lg btn-block"
          onClick={() => {
            selectModule("Reading Module 2");
          }}
        >
          Reading Module 2
        </button>
        <button
          className="btn btn-primary btn-lg btn-block"
          onClick={() => {
            selectModule("Mathematics Module 1");
          }}
        >
          Mathematics Module 1
        </button>
        <button className="btn btn-primary btn-lg btn-block"
          onClick={() => {
            selectModule("Mathematics Module 2");
          }}
        >
          Mathematics Module 2
        </button>
      </div>

<br></br>
      {isLoading === false && (
        <Fragment>
          {selectedModule === "Reading Module 1" ? (
            <Fragment>
              <p>Reading Module 1</p>
              <br></br>
              <QuestionsPreview
                deleteQuestion={deleteQuestion}
                questions={pkg}
              ></QuestionsPreview>
            </Fragment>
          ) : (
            ""
          )}
          {selectedModule === "Reading Module 2" ? (
            <Fragment>
              <p>Reading Module 2</p>
              <br></br>
              <QuestionsPreview
                deleteQuestion={deleteQuestion}
                questions={pkg}
              ></QuestionsPreview>
            </Fragment>
          ) : (
            ""
          )}
          {selectedModule === "Mathematics Module 1" ? (
            <Fragment>
              <p>Mathematics Module 1</p>
              <br></br>
              <QuestionsPreview
                deleteQuestion={deleteQuestion}
                questions={pkg}
              ></QuestionsPreview>
            </Fragment>
          ) : (
            ""
          )}
          {selectedModule === "Mathematics Module 2" ? (
            <Fragment>
              <p>Mathematics Module 2</p>
              <br></br>
               <QuestionsPreview
                deleteQuestion={deleteQuestion}
                questions={pkg}
              ></QuestionsPreview>
            
            </Fragment>
          ) : (
            ""
          )}
        </Fragment>
      )}

      {isLoading === true && <p>...Loading</p>}
    </div>
  );
};
export default ExamQuestionsPreview;
