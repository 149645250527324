import { useState } from "react";
import { useHistory } from "react-router-dom";
import Modal from "../UI/Modal";

const StartNavigator = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);

  const forwardToSATLogin = () => {
    history.push("login");
  };

  return (
    <div class="containerFill">
      {showModal === true ? (
        <Modal onHideCart={() => setShowModal(false)}>
          <div class="container">
            <p>
              Bu sınav ile ilgili bilgi almak için aşağıdaki telefonlarımızdan
              birine ulaşabilirsiniz.
            </p>
            <p>MERKEZ (0850) 532 87 87</p>
            <p>CADDEBOSTAN (0530) 640 77 01</p>
            <p>ATAŞEHİR (0553) 643 84 86</p>
            <p>ETİLER (0530) 265 36 40</p>
            <p><b>Mail: </b> yigit@veritasedu.com</p>
          </div>
        </Modal>
      ) : (
        ""
      )}
      <div class="container">
        <div class="row mb-3">
          <div class="col-md-12">
            <div class="section-title title-centered mb-80">
              <h3 class="h3-sm">Our Online Course Categories</h3>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-4">
            <div class="card h-100">
              <img
                class="card-img-top"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/34/New_SAT_Logo_%28vector%29.svg/350px-New_SAT_Logo_%28vector%29.svg.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h5 class="card-title">SAT Module</h5>
                <p class="card-text">
                  SAT Modülümze Aşağıdaki Butondan ulaşabilirsiniz.
                </p>
                <button class="btn btn-primary" onClick={forwardToSATLogin}>
                  Go to Login
                </button>
              </div>
            </div>
          </div>
          <div class="col-sm-4" onClick={() => setShowModal(true)}>
            <div class="card">
              <img
                class="card-img-top"
                src="https://mlwgs.com/wp-content/uploads/2018/04/AP-Logo.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h5 class="card-title">AP Module</h5>
                <p class="card-text">
                  AP Question Bank modulümüz geliştirme aşamasındadır
                </p>
                <a href="#" class="btn">
                  Geliştirme Aşamasında
                </a>
              </div>
            </div>
          </div>

          <div class="col-sm-4" onClick={() => setShowModal(true)}>
            <div class="card h-100">
              <img
                class="card-img-top"
                src="https://mindfish.com/wp-content/uploads/2016/04/ACT-Logo.png"
                alt="Card image cap"
              />

              <div class="card-body">
                <h5 class="card-title">ACT Module</h5>
                <p class="card-text">
                  ACT Modulümüz Geliştirilme Aşamasındadır
                </p>
                <a href="#" class="btn">
                  Geliştirme Aşamasında
                </a>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div class="row">
          <div class="col-sm-4 " onClick={() => setShowModal(true)}>
            <div class="card h-100">
              <img
                class="card-img-top"
                src="https://upload.wikimedia.org/wikipedia/commons/6/62/IB_logo.gif?20110921014431"
                alt="Card image cap"
              />

              <div class="card-body">
                <h5 class="card-title">IB Module</h5>
                <p class="card-text">
                  IB Question Bank projemiz geliştirme aşamasındadır
                </p>
                <a href="#" class="btn ">
                  Geliştirme Aşamasında
                </a>
              </div>
            </div>
          </div>

          <div class="col-sm-4" onClick={() => setShowModal(true)}>
            <div class="card h-100">
              <img
                class="card-img-top"
                src="https://pavaedu.com/wp-content/uploads/2019/06/imat-tip-egitimi-italya-pavaedu-e1618694156922-1200x720.jpg"
                alt="Card image cap"
              />

              <div class="card-body flex">
                <h5 class="card-title ">IMAT</h5>
                <p class="card-text ">IMAT</p>
                <a href="#" class="btn">
                  Geliştirme Aşamasında
                </a>
              </div>
            </div>
          </div>

          <div class="col-sm-4" onClick={() => setShowModal(true)}>
            <div class="card h-100">
              <img
                class="card-img-top"
                src="https://ukrlawcouncil.org/wp-content/uploads/2020/04/ednvu8xwsaaapck.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h5 class="card-title">LNAT</h5>
                <p class="card-text">LNAT</p>
                <a href="#" class="btn">
                  Geliştirme Aşamasında
                </a>
              </div>
            </div>
          </div>
        </div>
        <br></br>
        <div class="row">
          <div class="col-sm-4" onClick={() => setShowModal(true)}>
            <div class="card h-100">
              <img
                class="card-img-top"
                src="https://pavaedu.com/wp-content/uploads/2022/03/bmat-sinavi-nedir-1200x675.jpg"
                alt="Card image cap"
              />

              <div class="card-body">
                <h5 class="card-title">BMAT</h5>
                <p class="card-text">BMAT</p>
                <a href="#" class="btn">
                  Geliştirme Aşamasında
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartNavigator;
