import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Redirect, Route } from "react-router-dom";
import Welcome from "./Components/Welcome";
import Menu from "./Components/Menu";
import Questions from "./Components/Questions";
import LoginForm from "./Components/Login/LoginForm";
import QuizResult from "./Components/QuizResultScreen/QuizResult";
import QuestionForm from "./Components/QuestionAddForm/QuestionForm";
import QuizCategorySelectForm from "./Components/QuizCategorySelectForm";
import Students from "./Components/TeacherStudents/Students";
import { useSelector } from "react-redux";
import store from "./store/authContext";
import StudentQuizResults from "./Components/TeacherStudentSummary/studentQuizResults";
import StudentQuizCheck from "./Components/StudentQuizCheck/StudentQuizCheck";
import SATOverview from "./Components/SAT/Overview/SATOverview";
import MathematicsTest from "./Components/SAT/MathematicsTest/MathematicsTest";
import SATResult from "./Components/SATResult/SATResult";
import StudentSATResults from "./Components/StudentResults/StudentSATResults";
import { useState } from "react";
import ExamQuestionsPreview from "./Components/ExamQuestionsPreview/ExamQuestionsPreview";
import StudentAdd from "./Components/StudentAdd/StudentAdd";
import StudentSATResultDetails from "./Components/StudentResults/StudentSATResultDetails";
import StudentSATQuizResultDetails from "./Components/StudentResults/StudentSATQuizResultDetails";
import Selection from "./Components/Selection/Selection";
import StartNavigator from "./Components/StartNavigator/StartNavigator";

function App() {
  const user = useSelector((state) => state.auth.fullname);
  const role = useSelector((state) => state.auth.role);
  console.log("App");
  const [reset,setReset] = useState(0)

  const resetComponent = () =>{
    setReset(1)
    setReset(0)
  }

  return (
    <div className="backgroundDiv">
      <BrowserRouter>

          <Route path={"/navigator"}>
            <StartNavigator></StartNavigator>
          </Route>

        <Route path="/">
          <Redirect to="/navigator"></Redirect>
        </Route>

        <Route path="">
          <Redirect to="/navigator"></Redirect>
        </Route>

        <Route path="/selection">
          <Selection></Selection>
        </Route>

        {role !== "" && (
          <Route path="/Index">
            <Welcome User={user}></Welcome>
            <Menu></Menu>
          </Route>
        )}


        {role === "Student" && (
          <Route path="/Test">
            <Questions userprops={user}></Questions>
          </Route>
        )}

        <Route path="/Login">
          <LoginForm></LoginForm>
        </Route>
        {role === "Student" && (
          <Route path="/QuizResult">
            <QuizResult></QuizResult>
          </Route>
        )}
        {role === "Teacher" && (
          <Route path="/QuestionAdd">
            {reset === 0 &&
            <QuestionForm userprops={user} resetComponent={resetComponent}></QuestionForm>
          }
          </Route>
        )}
        {(role === "Teacher" || role === 'Admin') && (
          <Route path="/TeacherStudents">
            <Students></Students>
          </Route>
        )}

        {role === "Teacher" && (
          <Route path="/studentQuizResults">
            <StudentQuizResults></StudentQuizResults>
          </Route>
        )}

        {role === "Teacher" && (
          <Route path="/checkStudentQuiz">
            <StudentQuizCheck></StudentQuizCheck>
          </Route>
        )}

        {role === "Student" && (
          <Route path="/QuizSetup">
            <QuizCategorySelectForm></QuizCategorySelectForm>
          </Route>
        )}

        {role === "Student" && (
          <Route path="/SATOverview">
            <SATOverview></SATOverview>
          </Route>
        )}
      {role === "Teacher" && (
          <Route path="/createsat">
            <StudentQuizCheck></StudentQuizCheck>
          </Route>
        )}

        {role === "Student" && (
          <Route path="/SATResult">
            <SATResult></SATResult>
          </Route>
        )}

        {role === "Student" && (
          <Route path={"/StudentResults"}>
            <StudentSATResults></StudentSATResults>
          </Route>
        )}

        {(role === "Student" || role === 'Teacher')&& (
          <Route path={"/satresultdetails"}>
            <StudentSATResultDetails></StudentSATResultDetails>
          </Route>
        )}

        {role === "Teacher" && (
          <Route path="/SATPreview">
            <ExamQuestionsPreview></ExamQuestionsPreview>
          </Route>
        )}

        {role === "Admin" && (
          <Route path="/StudentAdd">
            <StudentAdd></StudentAdd>
          </Route>
        )}

        {(role === "Student" || role === 'Teacher' )&& (
          <Route path={"/satresultquizdetails"}>
            <StudentSATQuizResultDetails></StudentSATQuizResultDetails>
          </Route>
        )}

         


      </BrowserRouter>
    </div>
  );
}

export default App;
