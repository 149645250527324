import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";


const StudentQuizResults = (props) => {

    const history = useHistory();
    const [results, setResults] = useState([]);
    const role = useSelector((state) => state.auth.role);
    console.log(role)

    const loc = useLocation();
    const s = loc.data;

    console.log(s);

    const getStudentQuizzes = async() => {
        const response = await fetch("https://webapplication120221210090459.azurewebsites.net/api/getsubjectquizresults", {
            method: "POST",
            body: JSON.stringify({'studentid':s.studentid, 'subjectid':s.subjectid}),
            headers: {
              "Content-Type": "application/json",
            },
          });
      
          const da = await response.json();
          console.log(da);
          setResults(da)
    }

   useEffect(() => {
    getStudentQuizzes()
   },[]);

   const getQuizDetails = (resid) => {

    console.log(resid);
    history.push({ pathname: "./checkStudentQuiz", package: { 'id':resid } });
   }

    return(
        <Fragment>
            <p>Sınav Sonuçları</p>

            <table>
                <tr>
                    <th>ID</th>
                    <th>Student</th>
                    <th>Score</th>
                    <th>Details</th>
                </tr>
                {results.map((res) => {
                    console.log(res)
                    return(
                        <tr>
                            <td>{res.id}</td>
                            <td>{res.quizTaker.fullName}</td>
                            <td>{res.score}</td>
                            <td><button onClick={() => getQuizDetails(res.id)}>Details</button></td>
                        </tr>
                    )
                })}
            </table>
        </Fragment>         
        
    )


}

export default StudentQuizResults;