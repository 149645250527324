import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useHistory, useNavigate } from "react-router-dom";
import "./LoginForm.css";
import { authActions } from "../../store/authContext";
import Timer from "../Timer/Timer";

const LoginForm = (props) => {
  const [Invalid, setIsInvalid] = useState(false);
  let history = useHistory();

  const dispatch = useDispatch();

  const CheckCredentials = async (event) => {
    event.preventDefault();
    console.log(event.target.username.value);
    console.log(event.target.password.value);

    let x = null;

    console.log(
      JSON.stringify(event.target.username.value, event.target.password.value)
    );
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/loginCredentials",
      {
        method: "POST",
        body: JSON.stringify({
          username: event.target.username.value,
          password: event.target.password.value,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const da = await response.json();
    console.log(da);

    /*  for (var i = 0; i < Users.length; i++) {
      console.log(i);
      if (Users[i].Username === event.target.username.value) {
        console.log("Same Username");
        if (Users[i].Password === event.target.password.value) {
          console.log("Same Password");
          x = Users[i];
        }
      }
    }*/

    if (da.success === false) {
      setIsInvalid(true);
    } else {
      dispatch(
        authActions.login({
          userid: da.userID,
          fullname: da.fullName,
          role: da.role,
        })
      );
      history.push("./Index");
    }
  };

  return (
    <Fragment>
      <section class="vh-100">
        <div class="container-fluid h-custom centered-element">
          <div class="row d-flex justify-content-center align-items-center h-100">
            
            <div class="col-md-9 col-lg-6 col-xl-5">
              <img
                src="../VeritasLogo.jpeg"
                class="img-fluid"
                alt="Sample image"
              />
            </div>
            <div class="col-md-8 col-lg-6 col-xl-4 offset-xl-1">
              <form onSubmit={CheckCredentials}>
                <div class="d-flex flex-row align-items-center justify-content-center justify-content-lg-start"></div>

                <div class="divider d-flex align-items-center my-4"></div>

                <div class="form-outline mb-4">
                  <label class="form-label" for="form3Example3">
                    Username
                  </label>

                  <input
                    type="text"
                    id="form3Example3"
                    name="username"
                    class="form-control form-control-lg"
                  />
                </div>

                <div class="form-outline mb-3">
                  <label class="form-label" for="form3Example4">
                    Password
                  </label>
                  <input
                    type="password"
                    id="form3Example4"
                    name="password"
                    class="form-control form-control-lg"
                  />
                </div>

                <button className="btn btn-primary">Login</button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );

  return (
    <div className="fullPage">
      {Invalid === true ? <p>Kullanici Adi veya Şifreniz Yanliş</p> : ""}
      <form onSubmit={CheckCredentials}>
        <label>Username: </label>
        <br />
        <input
          type="text"
          name="username"
          className={`form-input ${Invalid === true ? "invalid" : ""}`}
        ></input>
        <br />
        <label>Password: </label>
        <br />
        <input
          type="password"
          name="password"
          className={`form-input ${Invalid === true ? "invalid" : ""}`}
        ></input>
        <br />
        <input type="submit" className="submit"></input>
      </form>
    </div>
  );
};

export default LoginForm;
