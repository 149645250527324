import {initializeApp} from 'firebase/app'
import {getStorage} from 'firebase/storage'



    const firebaseConfig = {
        apiKey: "AIzaSyB7CfC88-20-URybKuPLyQaxxHYHTkKRHQ",
        authDomain: "reactstore-c0df9.firebaseapp.com",
        projectId: "reactstore-c0df9",
        storageBucket: "reactstore-c0df9.appspot.com",
        messagingSenderId: "346625153479",
        appId: "1:346625153479:web:47167f1f4adc9562a2e1e2"
      };

    const App = initializeApp(firebaseConfig);
    const storage = getStorage(App);
    
    export default storage
