import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import classes from "./QuizCategorySelectForm.module.css";

const QuizCategorySelectForm = (props) => {
  let history = useHistory();
  const userid = useSelector((state) => state.auth.userid)
  console.log(userid);
  const[subjects, setSubjects] = useState([]);

  const [isValid, setIsValid] = useState(true);

  const getSubjects = async (id) => {
    const response = await fetch("https://localhost:5001/api/getstudentsubjects", {
      method: "POST",
      body: JSON.stringify(id),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const da = await response.json();
    setSubjects(da);
  }

  useEffect(() => {
    getSubjects(userid);
  }, []);

  const sendCategory = async (event) => {
    event.preventDefault();
    const index = event.target.category.options.selectedIndex - 1 ;
    console.log(index)
    console.log(JSON.stringify(subjects[index]));
    const cat = event.target.category.value;
    if (cat === "") {
      return;
    }

    const response = await fetch("https://localhost:5001/api/SendQuestions", {
      method: "POST",
      body: JSON.stringify(subjects[index]),
      headers: {
        "Content-Type": "application/json",
      },
    });
    const da = await response.json();

    
    da.Subject = subjects[index].id;
    console.log('Quiz')
    console.log(da);
    props.action(da);
  };

  return (
    <div className={classes.container}>
      {!isValid ? <p>Bir ders seçiniz</p> : ""}
      <form onSubmit={sendCategory}>

        <label className={classes.dropdown} for="slct">
          <select id="slct" required="required" className={classes.dropdown} name="category">
            <option value="" disabled="disabled" selected="selected">
              Ders Seçiniz
            </option>
          {subjects.map((subject)=> {
            return(
              <option>{subject.name}</option>
            )
          })}
          </select>
        </label>
        <input className={classes.inputButton} type="submit" value="Sınavı Başlat"></input>
        
      </form>
    </div>
  );
};

export default QuizCategorySelectForm;
