import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./SATResult.css";

const SATResult = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [result, setResult] = useState({});
  const loc = useLocation();
  const data = loc.state;
  const history = useHistory();

  const getExamResult = async () => {
    console.log(JSON.stringify({ id: data }));

    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/getsatresult",
      {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();
    console.log(da);

    const pkg = {
      Math1: da.mathematicsTestModule1,
      Math2: da.mathematicsTestModule2,
      Reading1: da.readingWritingTestModule1,
      Reading2: da.readingWritingTestModule2,
      MathScore: da.mathScore,
      ReadingScore: da.readingScore,
      TotalScore: da.totalScore,
    };

    setResult(pkg);
    setIsLoading(false);
  };

  useEffect(() => {
    getExamResult();
  }, []);

  console.log(result);

  if (isLoading === false) {
    return (
      <div className="container">
        <table className="table table-dark table-bordered">
          <thead>
            <tr>
              <th>Test</th>
              <th>Doğru</th>
              <th>Yanlış</th>
              <th>Boş</th>
            </tr>
          </thead>
          <tbody>
           
            <tr>
              <td>Reading Module 1</td>
              <td>{result.Reading1.totalRightAnswers}</td>
              <td>{result.Reading1.totalWrongAnswers}</td>
              <td>{result.Reading1.totalBlankAnswers}</td>
            </tr>

            <tr>
              <td>Reading Module 2</td>
              <td>{result.Reading2.totalRightAnswers}</td>
              <td>{result.Reading2.totalWrongAnswers}</td>
              <td>{result.Reading2.totalBlankAnswers}</td>
            </tr>

            <tr>
              <td>Mathematics Module 1</td>
              <td>{result.Math1.totalRightAnswers}</td>
              <td>{result.Math1.totalWrongAnswers}</td>
              <td>{result.Math1.totalBlankAnswers}</td>
            </tr>
            <tr>
              <td>Mathematics Module 2</td>
              <td>{result.Math2.totalRightAnswers}</td>
              <td>{result.Math2.totalWrongAnswers}</td>
              <td>{result.Math2.totalBlankAnswers}</td>
            </tr>

          </tbody>
        </table>

        <p>Math Score : {result.MathScore}</p>
        <p>Reading Score : {result.ReadingScore}</p>
        <p>Total Score : {result.TotalScore}</p>

        <br></br>


        <p>
        Veritas Academy olarak Digital SAT için güncel sınav sistemine uygun yepyeni bir portal geliştirmekteyiz. Web sitemizden SAT denemelerimizi çözdükçe bizlere görüş ve önerilerinizi de iletmenizi rica eder, iyi çalışmalar dileriz.
        </p>

        <button className="btn btn-primary" onClick={() => history.push('./Index')}>Back To Menu</button>
      </div>
    );
  }
};

export default SATResult;
