import { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import "./StudentSATQuizResultDetails.css";

const StudentSATQuizResultDetails = () => {
  const loc = useLocation();

  const x = loc.state.answeredQuestions;
  const [index, setIndex] = useState(0);

  console.log(x);

  const [question, setQuestion] = useState(x[0]);

  console.log(question.question.question.correctAnswer.answer);
  let questionNumber = 0;
  return (
    <div class="containerFill">
      <div className="container">
        {x.map((item) => {
          questionNumber = questionNumber + 1;
          if (item.status === "True") {
            return (
              <button
                onClick={(event) => {
                  setIndex(event.target.innerText - 1);
                  setQuestion(x[event.target.innerText - 1]);
                }}
                className="btn btn-outline-success questionNumber"
              >
                {questionNumber}
              </button>
            );
          }
          if (item.status === "False") {
            return (
              <button
                onClick={(event) => {
                  setIndex(event.target.innerText - 1);
                  setQuestion(x[event.target.innerText - 1]);
                }}
                className="btn btn-outline-danger questionNumber"
              >
                {questionNumber}
              </button>
            );
          }
          if (item.status === "Blank") {
            return (
              <button
                onClick={(event) => {
                  setIndex(event.target.innerText - 1);
                  setQuestion(x[event.target.innerText - 1]);
                }}
                className="btn btn-outline-dark questionNumber"
              >
                {questionNumber}
              </button>
            );
          }
        })}
        <br></br>

        <Fragment>
          <div className="questionContainer clearfix">
            <div className="questionText">
              <img
                className="questionTextImage"
                src={question.references["QuestionText"]}
              ></img>
              <p>{question.question.question.questionText}</p>
            </div>
            <div className="questionAnswers clearfixchild">
              {question.question.question.questionType === "Test" ? (
                <div>
                  <div
                    className={`potentialAnswerDiv ${
                      (question.correctAnswer === question.question.selected &&
                        question.correctAnswer === "A") ||
                      question.correctAnswer === "A"
                        ? " correct"
                        : question.question.selected === "A"
                        ? " selected"
                        : ""
                    }`}
                  >
                    {typeof question.references["Answer1"] === "undefined" ? (
                      ""
                    ) : (
                      <img
                        className="potentialAnswerImage"
                        src={question.references["Answer1"]}
                      ></img>
                    )}
                    <p>
                      {question.question.question.potentialAnswers[0].answer}
                    </p>
                  </div>

                  <div
                    className={`potentialAnswerDiv ${
                      (question.correctAnswer === question.question.selected &&
                        question.correctAnswer === "B") ||
                      question.correctAnswer === "B"
                        ? " correct"
                        : question.question.selected === "B"
                        ? " selected"
                        : ""
                    }`}
                  >
                    {typeof question.references["Answer2"] === "undefined" ? (
                      ""
                    ) : (
                      <img
                        className="potentialAnswerImage"
                        src={question.references["Answer2"]}
                      ></img>
                    )}
                    <p>
                      {question.question.question.potentialAnswers[1].answer}
                    </p>
                  </div>

                  <div
                    className={`potentialAnswerDiv ${
                      (question.correctAnswer === question.question.selected &&
                        question.correctAnswer === "C") ||
                      question.correctAnswer === "C"
                        ? " correct"
                        : question.question.selected === "C"
                        ? " selected"
                        : ""
                    }`}
                  >
                    {typeof question.references["Answer3"] === "undefined" ? (
                      ""
                    ) : (
                      <img
                        className="potentialAnswerImage"
                        src={question.references["Answer3"]}
                      ></img>
                    )}
                    <p>
                      {question.question.question.potentialAnswers[2].answer}
                    </p>
                  </div>

                  <div
                    className={`potentialAnswerDiv ${
                      (question.correctAnswer === question.question.selected &&
                        question.correctAnswer === "D") ||
                      question.correctAnswer === "D"
                        ? " correct"
                        : question.question.selected === "D"
                        ? " selected"
                        : ""
                    }`}
                  >
                    {typeof question.references["Answer4"] === "undefined" ? (
                      ""
                    ) : (
                      <img
                        className="potentialAnswerImage"
                        src={question.references["Answer4"]}
                      ></img>
                    )}
                    <p>
                      {question.question.question.potentialAnswers[3].answer}
                    </p>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <label>
                    Correct Answer:{" "}
                    {question.question.question.correctAnswer.answer}{" "}
                  </label>
                  <br></br>
                  <label>Your Answer: {question.question.selected}</label>
                </Fragment>
              )}

              {question.question.questionType === "Open-Ended" ? (
                <Fragment>
                  <p>Cevap Gösterilieck</p>
                </Fragment>
              ) : (
                ""
              )}
            </div>
          </div>
        </Fragment>
      </div>
    </div>
  );
};

export default StudentSATQuizResultDetails;
