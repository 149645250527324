import Modal from "../UI/Modal";
import { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./Students.css";

const Students = () => {
  const history = useHistory();
  const teacherid = useSelector((state) => state.auth.userid);
  const User = useSelector((state) => state.auth);

  console.log(User.role);

  const [students, setStudents] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [results, setResults] = useState([]);
  const [isModalShown, setModalShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [R1Incorrects, setR1Incorrects] = useState("");
  const [R2Incorrects, setR2Incorrects] = useState("");
  const [M1Incorrects, setM1Incorrects] = useState("");
  const [M2Incorrects, setM2Incorrects] = useState("");

  console.log(selectedStudent);
  const getStudents = async () => {
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/getstudents",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();
    console.log(da);

    setStudents(da);
  };

  const getExamDetails = async (id) => {
    console.log(id);
    setModalShown(true);
    setIsLoading(true);
    console.log(JSON.stringify(id));

    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/getstudentresultforadvisors",
      {
        method: "POST",
        body: JSON.stringify(parseInt(id)),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();
    console.log(da);

    setR1Incorrects(da["Reading 1"]);
    setR2Incorrects(da["Reading 2"]);
    setM1Incorrects(da["Math 1"]);
    setM2Incorrects(da["Math 2"]);

    setIsLoading(false);
  };

  const getStudentQuizDetails = async (quizId) => {
    console.log(quizId);
    const response = await fetch(
      "https://webapplication120221210090459.azurewebsites.net/api/student/getsatresultdetails",
      {
        method: "POST",
        body: JSON.stringify(quizId),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const da = await response.json();
    console.log(da);
    console.log("Redirect");
    setIsLoading(false);

    history.push({
      pathname: "./satresultdetails",
      state: da,
    });
  };
  useEffect(() => {
    getStudents();
  }, []);

  if (students.length == 0) {
    return <p>Loading ...</p>;
  }

  const directToQuizResults = (studentid, subjectid) => {
    history.push({
      pathname: "./studentQuizResults",
      data: { studentid: studentid, subjectid: subjectid },
    });
  };

  const getStudentQuizResults = async (event) => {
    event.preventDefault();

    if (!(selectedStudent === "")) {
      console.log(JSON.stringify({ id: selectedStudent }));

      const pkg = { id: parseInt(selectedStudent) };

      console.log(pkg);

      const response = await fetch(
        "https://webapplication120221210090459.azurewebsites.net/api/getstudentsatresults",
        {
          method: "POST",
          body: JSON.stringify(parseInt(selectedStudent)),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const da = await response.json();
      console.log(da);

      setResults(da);
    }
  };

  console.log(results);

  return (
    <Fragment>
      {isModalShown === true && (
        <Modal
          onHideCart={() => {
            setModalShown(false);
          }}
        >
          {isLoading === false ? (
            <div>
              <p>Reading & Writing Module 1 Yanlışları: {R1Incorrects}</p>
              <p>Reading & Writing Module 2 Yanlışları: {R2Incorrects}</p>
              <p>Mathematics Module 1 Yanlışları: {M1Incorrects}</p>
              <p>Mathematics Module 2 Yanlışları: {M2Incorrects}</p>
            </div>
          ) : (
            <p>Loading...</p>
          )}
        </Modal>
      )}
      <div class="containerFill">
        <div class="container">
          <form class>
            <div class="form-row">
              <div class="col-lg-4 centeredDiv">
                <select
                  class="form-select mx-auto"
                  name="studentSelector"
                  onChange={(event) => {
                    setSelectedStudent(event.target.value);
                  }}
                >
                  <option value=""></option>
                  {students.map((str) => {
                    return <option value={str.id}> {str.fullName}</option>;
                  })}
                </select>
              </div>
              <br></br>
              <br></br>
              <button class="btn btn-primary" onClick={getStudentQuizResults}>
                Select Student
              </button>
            </div>
          </form>
          <br></br>
          <table class="table table-dark table-bordered">
            <thead class="thead-dark">
              <tr>
                <th>Exam Name</th>
                <th>Reading Module 1</th>
                <th>Reading Module 2</th>
                <th>Mathematics Module 1</th>
                <th>Mathematics Module 2</th>
                <th>Math Score</th>
                <th>R/W Score</th>
                <th>Total Score</th>
                <th></th>
              </tr>
            </thead>
            <tbody class="tbody-dark">
              {results.map((str) => {
                return (
                  <tr>
                    <td>{str.examName}</td>
                    <td>{str.totalReadingRights} / 33</td>
                    <td>{str.totalReadingWrongs} / 33</td>
                    <td>{str.totalMathRights} / 27</td>
                    <td>{str.totalMathWrongs} / 27</td>
                    <td>{str.mathScore} / 800</td>
                    <td>{str.readingScore} / 800</td>
                    <td>{str.score} / 1600</td>
                    <td>
                      {User.role === "Admin" ? (
                        <button
                          onClick={() => {
                            getExamDetails(str.examID);
                          }}
                          class="btn btn-light"
                        >
                          Details
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            getStudentQuizDetails(str.examID);
                          }}
                          class="btn btn-light"
                        >
                          Details
                        </button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

export default Students;
