import { createSlice, configureStore } from "@reduxjs/toolkit";

const initialAuthenticationState = { userid: 0, userFullName: '', isAuthenticated: false, role: '' };

 const authSlice = createSlice({
    name: "auth",
    initialState: initialAuthenticationState,
    reducers: {
      login(state, action) {
        state.isAuthenticated=true;
        state.userid = action.payload.userid;
        state.userFullName =  action.payload.fullname;
        state.role = action.payload.role;
      },
      logout(state) {
        state.isAuthenticated = false;
        state.userid = 0;
        state.userFullName = '';
        state.role = '';

      }
    },
  });
    
  const store = configureStore({
    reducer: { auth: authSlice.reducer },
  });
  
  export const authActions = authSlice.actions;
  export default store;
