import classes from "./Modal.module.css";
import { Fragment } from "react";
import ReactDOM from "react-dom" 

const BackDrop = props => {
    return <div className={classes.backdrop} onClick={props.onClose}>

    </div>;
};

const ModalOverlay = props => {
    return <div className={classes.modal}>
        <div className={classes.content}>{props.children}</div>
    </div>
};

const portalElement = document.getElementById('overlays');

const Modal = props => {
    return <Fragment>
        {ReactDOM.createPortal(<BackDrop onClose = {props.onHideCart}/>, portalElement)}
        {ReactDOM.createPortal(<ModalOverlay action = {props.action}>{props.children}</ModalOverlay>,portalElement)}    </Fragment>
};

export default Modal;