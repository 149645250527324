import { Fragment, useState } from "react";
import "./CreateSAT.css";

const CreateSAT = (props) => {
    const [errorMessage, setErrorMessage] = useState("");
    const [hasError, setHasErrors] = useState(false);

  const sendRequest = async (event) => {
    event.preventDefault();
    console.log(event.target.satname.value);
    const response = await fetch("https://webapplication120221210090459.azurewebsites.net/api/addsattest", {
      method: "POST",
      body: JSON.stringify(event.target.satname.value),
      headers: {
        "Content-Type": "application/json",
      },
    });

    const da = await response.json();

    if(da.success == true){
        props.hideModal();
        props.updateQuiz();
    }
    else{
        setHasErrors(true);
        setErrorMessage(da.message)
    }
  };

  return (
    <div className="modalContainer">
      <form onSubmit={sendRequest}>
        {hasError === true && (
          <Fragment>
            <label className="errorLabel">{errorMessage}</label>
            <br></br>
          </Fragment>
        )}
        <label>SAT İsmini Giriniz:</label>
        <br></br>
        <input type="text" name="satname"></input>
        <br></br>
        <input type="submit"/>
      </form>
    </div>
  );
};

export default CreateSAT;
