import { useState } from "react";
import "./Question.css";

const Question = (props) => {
  const [isCorrect, setIsCorrect] = useState(false);
  const [isAnswered, setIsAnswered] = useState(false);

  let oldSelected = null;

  console.log('QuestionData:')
  console.log(props.item)

  const CheckAnswer = (event) => {
    if (oldSelected == null) {
      oldSelected = event.target;
      console.log(props.item.CorrectAnswer);
      console.log(event.target.innerText);
      let answer = parseInt(event.target.innerText);
      if (answer === props.item.CorrectAnswer) {
        oldSelected.style.backgroundColor = "green";
      } else {
        oldSelected.style.backgroundColor = "red";
      }
    } else {
      oldSelected.style.backgroundColor = "white";
      oldSelected = event.target;
      console.log(props.item.CorrectAnswer);

      console.log(event.target.innerText);
      let answer = parseInt(event.target.innerText);
      if (answer === props.item.CorrectAnswer) {
        oldSelected.style.backgroundColor = "green";
      } else {
        oldSelected.style.backgroundColor = "red";
      }
    }
  };



  const SelectAnswer = (event) => {
    props.onSelect(props.item.QuestionNumber, parseInt(event.target.innerText));
  };
  console.log(props.QuestionTextImageUrl)
  return (
    <div>
      <img src={props.item.QuestionTextImageUrl} alt='QuestionText'></img>
      <p>{props.item.QuestionText}</p>

      <div onClick={() => {console.log('Answer1 Div Clicked')}} style={{backgroundColor : 'green'}}>
      {typeof(props.item.Answer1Image) === 'undefined' ? '' : <img src={props.item.Answer1Image} alt="Answer1"></img>}       
      {props.item.Answer1 === '' ? '' : <button
        onClick={SelectAnswer}
        className={`option ${
          props.item.Answer1 === props.item.Selected ? "selected" : ""
        } `}
      >
       {props.item.Answer1}
      </button>
      }
      </div>
<div>


      {typeof(props.item.Answer2Image) === 'undefined' ? '' : <img src={props.item.Answer2Image} alt="Answer2"></img>} 
      {props.item.Answer2 === '' ? '' :
      <button
        onClick={SelectAnswer}
        className={`option ${
          props.item.Answer2 === props.item.Selected ? "selected" : ""
        } `}
      >
        {props.item.Answer2}
      </button>}
      </div>

      <div>
      {typeof(props.item.Answer3Image) === 'undefined' ? '' : <img src={props.item.Answer3Image} alt="Answer3"></img>} 
      {props.item.Answer3 === '' ? '' :<button
        onClick={SelectAnswer}
        className={`option ${
          props.item.Answer3 === props.item.Selected ? "selected" : ""
        } `}
      >
        {props.item.Answer3}
      </button>}

      </div>

      <div>

     
     {typeof(props.item.Answer4Image) === 'undefined' ? '' : <img src={props.item.Answer4Image} alt="Answer4"></img>} 

     {props.item.Answer4 === '' ? '' :<button
        onClick={SelectAnswer}
        className={`option ${
          props.item.Answer4 === props.item.Selected ? "selected" : ""
        } `}
      >
        {props.item.Answer4}
      </button>}
      </div>
      <br>
      </br>

      <button className="questionNavigator" onClick={props.previousQuestionClickHandler}>Previous Question</button>
      <button className="questionNavigator" onClick={props.nextQuestionClickHandler}>Next Question</button>

    </div>
  );
};

export default Question;
